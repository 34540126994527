<template>
  <div class="ss-manage-box">
    <footer class="ss-manage-footer">
      <el-button @click="close(false)">取消</el-button>
      <el-button @click="preVisible = true">预览</el-button>
      <el-button type="primary" @click="debounce(confirm, 1000)"
        >保存</el-button
      >
    </footer>
    <section class="ss-manage-box-content">
      <div class="ss-manage-box-left">
        <ManageItem
          title="上传图片"
          color="#f14f46"
          word="推荐上传尺寸为：1380X740 px"
          style="margin-top: 0"
        >
          <upload :info="imgInfo" class="upload-img" @getimage="getimage" />
        </ManageItem>
        <ManageItem title="主标题">
          <el-input
            v-model="articleData.title"
            placeholder="请输入主标题"
          ></el-input>
        </ManageItem>
        <ManageItem title="发布人">
          <el-input
            v-model="articleData.author"
            placeholder="请输入发布人"
          ></el-input>
        </ManageItem>
        <ManageItem title="发布时间">
          <el-date-picker
            v-model="articleData.publish_time"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </ManageItem>
        <div
          v-if="
            articleData.pv ||
            articleData.pv === 0 ||
            articleData.uv ||
            articleData.uv === 0
          "
          class="footer"
        >
          <p class="title">数据</p>
          <p>PV: {{ articleData.pv }}</p>
          <p>UV: {{ articleData.uv }}</p>
        </div>
        <button v-if="listData.data" class="footer-btn" @click="look">
          查看操作记录
        </button>
      </div>
      <div class="ss-manage-box-right">
        <el-button type="primary" @click="goodsInfo.visible = true"
          >插入商品</el-button
        >
        <div class="ss-manage-box-right-content">
          <ManageItem title="编辑内容" style="margin-top: 0">
            <vue-text :value="articleData.content" ref="editor" @echo="getContent"></vue-text>
          </ManageItem>
        </div>
      </div>
    </section>
    <go-dialog v-model="visible" width="584px" height="400px">
      <p class="title" style="font-size: 20px">操作人记录</p>
      <el-table :data="listData.data">
        <el-table-column prop="operation_user" label="操作人"></el-table-column>
        <el-table-column prop="update_time" label="操作时间"></el-table-column>
        <el-table-column prop="operation_desc" label="详情"></el-table-column>
      </el-table>
      <br />
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        page-size="5"
        layout="total, prev, pager, next, jumper"
        :total="listData.total"
      >
      </el-pagination>
    </go-dialog>
    <go-dialog v-model="preVisible" width="375px" height="70vh">
      <div class="preview-box">
        <el-Image
          class="preview-image"
          :src="`${
            articleData.img && !articleData.img.includes('http')
              ? 'http://' + articleData.img
              : articleData.img
          }`"
        />
        <h3 class="preview-title">{{ articleData.title }}</h3>
        <div class="preview-user">
          <span class="user-name">{{ articleData.author }}</span>
          <span class="publish-time">{{ articleData.publish_time }}</span>
        </div>
        <div class="preview-content" v-html="articleData.content"></div>
      </div>
    </go-dialog>
    <go-dialog v-model="goodsInfo.visible" width="500px" height="300px">
      <div class="goods-box">
        <p>
          <el-input
            v-model="goodsInfo.good_url"
            placeholder="请输入商品链接"
            @blur="goods"
          ></el-input>
        </p>
        <div class="goods-box-flex">
          <img :src="goodsInfo.img" />
          <div>
            <p>商品标题：{{ goodsInfo.title }}</p>
            <p>站点：{{ goodsInfo.site }}</p>
            <p>商品价格：{{ goodsInfo.price }}日元</p>
          </div>
        </div>
        <footer>
          <el-button type="primary" @click="cancel">取消</el-button>
          <el-button type="primary" @click="save">确定</el-button>
        </footer>
      </div>
    </go-dialog>
  </div>
</template>
<script>
import Upload from "@/components/common/Upload";
import ManageItem from "@/components/commonUI/ManageItem";
import VueText from "@/components/common/Textarea"
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { Upload, ManageItem, VueText },
  setup(props, { root, refs }) {
    const number = Math.floor(Math.random() * 100);
    const visible = ref(false);
    const listData = ref({});
    const preVisible = ref(false);
    let articleData = reactive({
      id: undefined,
      img: undefined,
      title: undefined,
      author: undefined,
      content: undefined,
      publish_time: undefined,
    });
    const page = ref(1);
    const imgInfo = reactive({
      width: 1380,
      height: 740,
      url: "",
    });
    const goodsInfo = reactive({
      good_url: "",
      visible: false,
      img: "",
      title: "",
      price: "",
      site: "",
      id: "",
      siteId: "",
    });
    watch(
      () => goodsInfo.visible,
      (val) => {
        if (!val) {
          cancel();
        }
      }
    );
    const close = async (flag) => {
      if (!flag) {
        const confirm = await root.$goDialog("确定要取消吗？");
        if (!confirm) return;
      }
      root.$router.back();
    };
    const id = root.$route.query.id;
    const getInfo = async () => {
      try {
        const res = await root.$axios.get(`/cms/article/detail/${id}`);
        if (res.code === 10000) {
          for (let key in res.data) {
            articleData[key] = res.data[key];
          }
          imgInfo.url = res.data.img;
          refs.editor.setContent(articleData.content)
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    };
    const handleCurrentChange = (e) => {
      page.value = e;
      getList();
    };
    const getList = async () => {
      try {
        const res = await root.$axios.get(`/cms/article/record/list`, {
          params: {
            article_id: id,
            page: page.value,
          },
        });
        if (res.code === 10000) {
          listData.value = res.data;
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      getInfo();
    }
    const getimage = (data) => {
      imgInfo.url = data;
      articleData.img = data;
    };
    const confirm = async () => {
      if (!imgInfo.url) {
        root.$message.warning("请上传图片");
        return;
      }
      if (!articleData.title) {
        root.$message.warning("请输入主标题");
        return;
      }
      if (!articleData.author) {
        root.$message.warning("请输入发布人");
        return;
      }
      if (!articleData.publish_time) {
        root.$message.warning("请选择发布时间");
        return;
      }
      if (!articleData.content) {
        root.$message.warning("请输入文章内容");
        return;
      }
      try {
        const res = await root.$axios.post("/cms/article/save", {
          id: articleData.id || undefined,
          img: articleData.img,
          title: articleData.title,
          author: articleData.author,
          content: articleData.content,
          publish_time: articleData.publish_time,
        });
        if (res.code === 10000) {
          root.$message.success(`${articleData.id ? "修改" : "保存"}成功`);
          close(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const goods = () => {
      if (!goodsInfo.good_url || !goodsInfo.good_url.trim()) return;
      root.$axios
        .post("/cms/topic/goods/search_goods", {
          goods_url: goodsInfo.good_url,
        })
        .then((res) => {
          goodsInfo.site = res.data.productDetails.site;
          goodsInfo.siteId = res.data.productDetails.siteId;
          getGoods(res.data.productDetails, goodsInfo);
        })
        
    };
    const getGoods = (data, item) => {
      root.$axios
        .post("/cms/topic/goods/get_goods_detail", {
          goods_id: data.id,
          site: data.site,
        })
        .then((res) => {
          goodsInfo.id = data.id;
          item.img = res.data.images[0];
          item.title = res.data.name;
          item.price = res.data.yenPrice;
        });
    };
    const getContent = (e) => {
      articleData.content = e
    }
    const save = () => {
      if (!goodsInfo.title) {
        root.$message.warning("缺乏商品信息");
        return;
      }
      refs.editor.setContent(
        `<div class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="display:flex;width:345px;height:110px;background-color:#F9FAFA;padding: 10px;margin:0 auto;border-radius: 4px;align-items: center;margin-top:10px;">
          <img class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="width:88px;height:88px;flex-shrink:0;" src="${goodsInfo.img}" />
          <div class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="width:100%;overflow:hidden;padding-left: 15px;">
            <p class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="overflow: hidden;text-overflow: ellipsis;white-space:nowrap;font-size: 16px;font-weight: 600;color: #333333;margin-top:0;">${goodsInfo.title}</p>
            <p class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="font-size: 12px;color:#ccc;">${goodsInfo.site}</p>
            <div class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="font-size: 16px;font-weight: 500;color: #F14F46;line-height: 16px;display:flex;justify-content:space-between;white-space: nowrap;align-items: center;">
              <span class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="white-space: nowrap;display:block;">${goodsInfo.price}日元</span><span class="jump" data-id="${goodsInfo.id}" data-siteId="${goodsInfo.siteId}" data-sitename="${goodsInfo.site}" style="white-space: nowrap;display:block;"><img class="jump" style="width:14px;height:14px;" src="https://ss2.030mall.com/coupon/d782ce02aa0d7cf6e566c5f06bf1fffa" />去购买</span>
            </div>
          </div>
        </div><p></p>`
      );
      cancel();
    };
    const cancel = () => {
      for (let key in goodsInfo) {
        goodsInfo[key] = "";
      }
      goodsInfo.visible = false;
    };
    const look = () => {
      visible.value = true;
    };
    return {
      articleData,
      preVisible,
      visible,
      listData,
      page,
      imgInfo,
      number,
      goodsInfo,
      close,
      confirm,
      getimage,
      look,
      handleCurrentChange,
      save,
      cancel,
      goods,
      getContent
    };
  },
});
</script>
<style lang="scss" scoped>
::v-deep .edui-editor {
  width: 375px !important;
  z-index: 1 !important;
}
::v-deep .edui-default .edui-editor-iframeholder {
  width: 100% !important;
  height: 150px;
  // height: 100% !important;
}
.ss-manage-box {
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 14px 14px 0 0;
  padding: 26px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: $titleColor;
    line-height: 28px;
    flex-shrink: 0;
    height: 28px;
  }
  .ss-manage-box-content {
    display: flex;
    position: relative;
    height: 100%;
    & > div {
      width: 100%;
      padding-right: 54px;
    }
    .el-input {
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
    .ss-manage-box-left {
      padding-bottom: 40px;
      .footer {
        margin-top: 40px;
        background-color: #fafafa;
        padding: 16px;
        color: #666;
        font-size: 14px;
        .title {
          font-size: 16px;
          color: #333;
        }
      }
      .footer-btn {
        background-color: #fff;
        border: none;
        outline: 1px solid $mainColor;
        color: $mainColor;
        width: 144px;
        height: 32px;
        border-radius: 16px;
        margin-top: 24px;
        margin-left: 1px;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .ss-manage-box-right {
      padding-left: 51px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .ss-manage-box-right-content {
        height: 100%;
      }
    }
  }
  .ss-manage-footer {
    flex: auto;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
    .el-button {
      width: 120px;
      height: 36px;
      margin-left: 24px;
    }
  }
}
.icon {
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  background-color: #a7abbd;
  color: #fff;
  border-radius: 100%;
  text-align: center;
}
.upload-img {
  width: 536px;
  height: 300px;
  padding: 14px;
  background-color: #fafafa;
}
.preview-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  overflow: auto;
}
.preview-image {
  width: 100%;
  flex-shrink: 0;
}
.preview-title {
  margin-top: 10px;
  font-size: 20px;
  white-space: pre-wrap;
  word-break: break-all;
}
.preview-user {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 10px;
  .user-name {
    font-size: 12px;
    color: #ff6a5b;
  }
  .publish-time {
    font-size: 12px;
    color: #999;
  }
}
.preview-content {
  width: 100%;
  height: 100%;
  ::v-deep {
    * {
      max-width: 100%;
    }
  }
}
.goods-box-flex {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  img {
    width: 140px;
    height: 80px;
  }
  & > div {
    padding: 10px 20px;
  }
}
.editor {
  width: 100%;
  ::v-deep {
    * {
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
</style>