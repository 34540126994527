<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
    <textarea :id="tinymceId" class="tinymce-textarea"></textarea>
    <div class="editor-custom-btn-container"></div>
  </div>
</template>
 
<script>
/* eslint-disable no-undef */
const plugins = 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em formatpainter axupimgs';
const toolbar = 'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
    styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
    table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs';
const font_formats ='微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;'
export default {
  name: "Tinymce",
  props: {
    id: {
      type: String,
      default: function () {
        return (
          "vue-tinymce-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      },
    },
    value: {
      type: String,
      default: "",
    },
    isTextNum:{
      type: Boolean,
      default: false,
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menubar: {
      type: String,
      default: "",
    },
    height: {
      type: [Number, String],
      required: false,
      default: 400,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 407,
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
    };
  },
  computed: {
    containerWidth() {
      const width = this.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`;
      }
      return width;
    },
  },
  mounted() {
    this.initTinymce()
  },
  activated(){
    if(tinymce){
      this.initTinymce();
    }
  },
  deactivated() {
    this.destroyTinymce();
  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    removeHTMLTag: function (str) {
      str = str.replace(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))*>/g, '');
      str = str.replace(/<\/?[^>]*>/g, '');
      str = str.replace(/[ | ]*\n/g, '\n');
      str = str.replace(/&nbsp;/ig, '');
      str = str.replace(/，/g, '');
      str = str.replace(/。/g, '');
      str = str.replace(/\s/g, '');
      return str;
    },
    initTinymce() {
      const _this = this;
      tinymce.init({
        selector: `#${this.tinymceId}`,
        language: 'zh_CN',
        plugins: plugins,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        height: this.height,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats: font_formats,
        importcss_append: true,
        toolbar_sticky: true,
        autosave_ask_before_unload: false,
        setup: function(editor) {
          editor.on('input undo redo execCommand', function() {
            _this.hasChange = false;
            _this.$emit("echo", editor.getContent());
          })
        },
        init_instance_callback: (editor) => {
          editor.setContent(_this.value);
          editor.on("NodeChange Change KeyUp SetContent", () => {
            _this.$emit("echo", editor.getContent());
          });
        },
        convert_urls: false,
      });
    },
    destroyTinymce() {
      const tinymce1 = tinymce.get(this.tinymceId);
      if (this.fullscreen) {
        tinymce1.execCommand("mceFullScreen");
      }
      if (tinymce1) {
        tinymce1.destroy();
      }
    },
    setContent(value) {
      tinymce.get(this.tinymceId).insertContent(value);
    },
    getContent() {
      tinymce.get(this.tinymceId).getContent();
    },
  },
};
</script>
 
<style lang="scss" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
  padding: 0 3px 0 0;
}
 
.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}
 
.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
 
.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}
 
.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
 
.editor-upload-btn {
  display: inline-block;
}
</style>