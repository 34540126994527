var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ss-manage-box"},[_c('footer',{staticClass:"ss-manage-footer"},[_c('el-button',{on:{"click":function($event){return _vm.close(false)}}},[_vm._v("取消")]),_c('el-button',{on:{"click":function($event){_vm.preVisible = true}}},[_vm._v("预览")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.debounce(_vm.confirm, 1000)}}},[_vm._v("保存")])],1),_c('section',{staticClass:"ss-manage-box-content"},[_c('div',{staticClass:"ss-manage-box-left"},[_c('ManageItem',{staticStyle:{"margin-top":"0"},attrs:{"title":"上传图片","color":"#f14f46","word":"推荐上传尺寸为：1380X740 px"}},[_c('upload',{staticClass:"upload-img",attrs:{"info":_vm.imgInfo},on:{"getimage":_vm.getimage}})],1),_c('ManageItem',{attrs:{"title":"主标题"}},[_c('el-input',{attrs:{"placeholder":"请输入主标题"},model:{value:(_vm.articleData.title),callback:function ($$v) {_vm.$set(_vm.articleData, "title", $$v)},expression:"articleData.title"}})],1),_c('ManageItem',{attrs:{"title":"发布人"}},[_c('el-input',{attrs:{"placeholder":"请输入发布人"},model:{value:(_vm.articleData.author),callback:function ($$v) {_vm.$set(_vm.articleData, "author", $$v)},expression:"articleData.author"}})],1),_c('ManageItem',{attrs:{"title":"发布时间"}},[_c('el-date-picker',{attrs:{"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":"选择日期时间"},model:{value:(_vm.articleData.publish_time),callback:function ($$v) {_vm.$set(_vm.articleData, "publish_time", $$v)},expression:"articleData.publish_time"}})],1),(
          _vm.articleData.pv ||
          _vm.articleData.pv === 0 ||
          _vm.articleData.uv ||
          _vm.articleData.uv === 0
        )?_c('div',{staticClass:"footer"},[_c('p',{staticClass:"title"},[_vm._v("数据")]),_c('p',[_vm._v("PV: "+_vm._s(_vm.articleData.pv))]),_c('p',[_vm._v("UV: "+_vm._s(_vm.articleData.uv))])]):_vm._e(),(_vm.listData.data)?_c('button',{staticClass:"footer-btn",on:{"click":_vm.look}},[_vm._v(" 查看操作记录 ")]):_vm._e()],1),_c('div',{staticClass:"ss-manage-box-right"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.goodsInfo.visible = true}}},[_vm._v("插入商品")]),_c('div',{staticClass:"ss-manage-box-right-content"},[_c('ManageItem',{staticStyle:{"margin-top":"0"},attrs:{"title":"编辑内容"}},[_c('vue-text',{ref:"editor",attrs:{"value":_vm.articleData.content},on:{"echo":_vm.getContent}})],1)],1)],1)]),_c('go-dialog',{attrs:{"width":"584px","height":"400px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('p',{staticClass:"title",staticStyle:{"font-size":"20px"}},[_vm._v("操作人记录")]),_c('el-table',{attrs:{"data":_vm.listData.data}},[_c('el-table-column',{attrs:{"prop":"operation_user","label":"操作人"}}),_c('el-table-column',{attrs:{"prop":"update_time","label":"操作时间"}}),_c('el-table-column',{attrs:{"prop":"operation_desc","label":"详情"}})],1),_c('br'),_c('el-pagination',{attrs:{"current-page":_vm.page,"page-size":"5","layout":"total, prev, pager, next, jumper","total":_vm.listData.total},on:{"current-change":_vm.handleCurrentChange}})],1),_c('go-dialog',{attrs:{"width":"375px","height":"70vh"},model:{value:(_vm.preVisible),callback:function ($$v) {_vm.preVisible=$$v},expression:"preVisible"}},[_c('div',{staticClass:"preview-box"},[_c('el-Image',{staticClass:"preview-image",attrs:{"src":("" + (_vm.articleData.img && !_vm.articleData.img.includes('http')
            ? 'http://' + _vm.articleData.img
            : _vm.articleData.img))}}),_c('h3',{staticClass:"preview-title"},[_vm._v(_vm._s(_vm.articleData.title))]),_c('div',{staticClass:"preview-user"},[_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.articleData.author))]),_c('span',{staticClass:"publish-time"},[_vm._v(_vm._s(_vm.articleData.publish_time))])]),_c('div',{staticClass:"preview-content",domProps:{"innerHTML":_vm._s(_vm.articleData.content)}})],1)]),_c('go-dialog',{attrs:{"width":"500px","height":"300px"},model:{value:(_vm.goodsInfo.visible),callback:function ($$v) {_vm.$set(_vm.goodsInfo, "visible", $$v)},expression:"goodsInfo.visible"}},[_c('div',{staticClass:"goods-box"},[_c('p',[_c('el-input',{attrs:{"placeholder":"请输入商品链接"},on:{"blur":_vm.goods},model:{value:(_vm.goodsInfo.good_url),callback:function ($$v) {_vm.$set(_vm.goodsInfo, "good_url", $$v)},expression:"goodsInfo.good_url"}})],1),_c('div',{staticClass:"goods-box-flex"},[_c('img',{attrs:{"src":_vm.goodsInfo.img}}),_c('div',[_c('p',[_vm._v("商品标题："+_vm._s(_vm.goodsInfo.title))]),_c('p',[_vm._v("站点："+_vm._s(_vm.goodsInfo.site))]),_c('p',[_vm._v("商品价格："+_vm._s(_vm.goodsInfo.price)+"日元")])])]),_c('footer',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("确定")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }